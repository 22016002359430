<template>
  <page-view ref="pageView" :title="title" left-arrow>
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{}" />
    </template>

    <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
      @submit="onSubmit" @failed="onFailed">
      <van-cell-group class="mt-10">
        <van-field v-model="form.yhbh" name="yhbh" label="户号" readonly />
        <van-field v-model="form.yhmc" name="yhmc" label="用户名称" readonly />
      </van-cell-group>

      <van-cell-group class="mt-10">
        <van-field v-model="form.yhdz" name="yhdz" label="用电地址" placeholder="" clearable
          :rules="[{ required: true, message: '请输入用电地址' }]" />
        <van-field v-model="form.lxr" name="lxr" label="联系人" placeholder="" clearable
          :rules="[{ required: true, message: '请输入用电地址' }]" />
        <van-field v-model="form.lxsj" name="lxsj" label="联系手机" type="number" placeholder="" clearable
          :rules="[{ required: true, message: '请输入用电地址' }]" />
        <van-field v-model="form.bzsm" name="bzsm" label="销户说明" type="textarea" placeholder="请输入销户说明"
          :rules="[{ required: true, message: '请输入销户说明' }]" />
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提 交</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
  import {
    DropdownMenu,
    DropdownItem
  } from 'vant';
  import {
    mapGetters
  } from 'vuex'
  import Loading from '@/components/LoadingLayout/loading.vue';
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import YhbhSelect from '@/components/YhbhSelect/index.vue';

  import {yhJbxx_fetchOneYhJbxxByYhbh} from '@/api/zhcx';
  export default {
    name: 'YhzdQuery',
    components: {
      'van-dropdown-menu': DropdownMenu,
      'van-dropdown-item': DropdownItem,
      Loading,
      YhbhEmpty,
      YhbhSelect,
    },
    data() {
      return {
        title: '用电销户',
        form: {
          yhbh: '',
          yhmc: '',
          yhdz: '',
          lxr: '',
          lxsj: '',
          bzsm: ''
        }
      }
    },
    methods: {
      yhbhChangeHandle(yhxx) {
        this.form.yhbh = yhxx.yhbh;
        this.fetchYhJbxx();
      },
      fetchYhJbxx() {
        this.$toast.loading('加载中...')
        yhJbxx_fetchOneYhJbxxByYhbh(this.form.yhbh).then(res => {
          this.form.yhbh = res.content.yhbh;
          this.form.yhmc = res.content.yhmc;
          this.form.yhdz = res.content.yhdz;
          this.form.lxr = res.content.lxr;
          this.form.lxsj = res.content.lxsj;
        }).finally(() => {
          this.$toast.clear();
        });
      },
      onSubmit(values) {
        console.log('submit', values);
        console.log(this.form);
      },
      onFailed({
        values,
        errors
      }) {
        console.log('failed:, Errors:', errors)
        this.$notify({
          type: 'danger',
          message: errors[0].message
        });
      }
    },
    mounted() {
      this.$store.dispatch('getYhbhList').then(list => {
        if (list.length) {
          this.form.yhbh = this.$route.query.yhbh ? this.$route.query.yhbh : list[0].yhbh;
          this.fetchYhJbxx();
        }
      });
    },
  }
</script>

<style lang="scss" scoped>

</style>